import 'swiper/css';
import 'swiper/css/pagination';
import ProductItem from './ProductItem';
import { IProductItem } from 'lib/interface';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { viewItemListEvent } from 'lib/helpers/gtm/events';
import { categoryUrlGenerator } from 'utils/category-url';

interface ICarouselProps {
  cmsItem: any;
  item_list_name?: string;
}

const ProductGrid = ({ cmsItem, item_list_name }: ICarouselProps) => {
  const { t } = useTranslation('common');
  const { push } = useRouter();

  useEffect(() => {
    viewItemListEvent(cmsItem.data, item_list_name || '');
  }, []);

  const id = cmsItem?.target;

  return (
    <div className='py-6 max-w-[1152px] mx-auto'>
      <div className='flex items-center justify-between px-2'>
        <h1 className='text-gray-900 mx-2 text-[20px] font-bold'>{cmsItem.title}</h1>
        {id && (
          <button onClick={() => push(id)} className='text-primary font-[600] mx-2' name={cmsItem.title}>
            {t('see_all')} {'>'}
          </button>
        )}
      </div>
      <div className='grid gap-3 px-4 2xl:grid-cols-6 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-3 grid-cols-2'>
        {cmsItem?.data?.map((item: IProductItem) => (
          <ProductItem key={item.entity_id} product={item} className='w-full' item_list_name={item_list_name} />
        ))}
      </div>
    </div>
  );
};

export default ProductGrid;
