import clsx from 'clsx';
import ProductCarousel from 'components/ProductCarousel';

const ProductsCarousel = ({ cmsItem, push, t, center }: any) => {
  const id = cmsItem?.target;
  return (
    <div className={clsx('max-w-[1152px] bg-dark-white pb-4 md:pb-0 md:bg-white', center && 'mx-auto')}>
      <div className='flex items-center justify-between px-2'>
        <h1 className='text-gray-900 mx-2 text-[20px] font-bold'>{cmsItem.title}</h1>
        {id && (
          <button
            onClick={() => {
              push(id);
            }}
            className='text-primary font-[600] mx-2'
          >
            {t('see_all')} {'>'}
          </button>
        )}
      </div>
      <div className='px-0'>
        <ProductCarousel products={cmsItem?.data} item_list_name={cmsItem.title} />
      </div>
    </div>
  );
};

export default ProductsCarousel;
