import CategoryCarousel from 'components/CategoryCarousel';
import PharmacyImage from 'components/PharmacyImage';
import { memo } from 'react';

const CategoriesCarousel = ({ cmsItem, push, t, isPLP }: any) => {
  const id = cmsItem?.target;
  return (
    <div className={`block mx-auto max-w-[1152px] py-10`}>
      <div className='flex items-center justify-between px-2'>
        {!isPLP && <h1 className='text-gray-900 mx-2 text-[20px] font-bold'>{cmsItem.title}</h1>}
        {id && (
          <>
            <button
              onClick={() => {
                push(id).then(() => {
                  if (isPLP) window.location.reload();
                });
              }}
              className='text-primary font-[600] mx-2'
            >
              {t('see_all')} {'>'}
            </button>
          </>
        )}
      </div>
      <div className={isPLP ? 'flex' : 'px-2'}>
        {isPLP && (
          <div className='hidden lg:block mt-[32px]'>
            <button className='swiper-cat-button-prev'>
              <PharmacyImage src='/local/image/icon-left.png' width={22} height={25} />
            </button>
          </div>
        )}
        <CategoryCarousel
          push={push}
          isPLP={isPLP}
          categories={cmsItem.data?.sort((a, b) => a.position - b.position)}
        />
        {isPLP && (
          <div className='hidden lg:block mt-[32px]'>
            <button className='swiper-cat-button-next'>
              <PharmacyImage src='/local/image/icon-right.png' width={22} height={25} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(CategoriesCarousel);
